@import '../../global/styles/lib';

$slider-speed: 750ms;
$long-duration: 1s;
$delay-speed: 650ms;
$ease: ease-in-out;
$home-slider-transition: $slider-speed $ease;
$scale-small: 0.8;
$scale-large: 1.3;

.home-slider {
    @include full-bleed;
    padding: 2rem 0;
    border-bottom: 1rem solid $most-red;
    opacity: 1;
    transition: opacity $hover-transition;
    
    &.no-vis {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
    } // .no-vis


    .arrows {
        @include absolute-full;
        right: 0;
        max-width: $row-max-width;
        margin: auto;

        button {
            &:hover,
            &:focus {
                background: $transparent !important;
            }
        } // button

        .slick-arrow {
            z-index: 1;
            &::before {
                color: $most-dark-gray;
            } // &::before
            &.slick-next {
                right: 0.25rem;
            } // &.slick-next
            &.slick-prev {
                left: 0.25rem;
            } // &.slick-prev
        } // .slick-arrow
    } // .arrows

    .slick-list {
        overflow: visible;
    } // .slick-list

    .slick-track {
        display: flex;
        align-items: center;
        
        &::before {
            outline: none;
        } // &::before
        #{$hovfoc}{
            outline: none;
        } // #{$hovfoc}
    } // .slick-track

    .slick-slide {
        position: relative;
        transform: scale($scale-small);
        transition: transform $long-duration $ease;
        transition-delay: 0ms;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: $row-max-width;
            margin: auto;
            padding: 0 2rem;
            opacity: 0;
            transition: opacity $long-duration $ease;

            &.show {
                opacity: 1;
            } // &.show

            img {
                margin: auto;
                margin-bottom: 1rem; 
                width: 5rem;
            } // img

            .slick-active {
                transform: scale(1);
            } // .slick-active

            .content {
                width: 100%;
                display: inline-block;
                align-self: center;
                margin-bottom: 1rem;
                text-align: center;
                .title {
                    font-weight: 700;

                } // .title
                .sep {
                    margin: 0 0.5rem;
                } // .sep
                .pub-date {
                    font-style: italic;
                } // .pub-date
                .sep, .pub-date {
                    font-size: 1rem;
                } // .seop, .pub-date
            } // .content
    
            .link {
                display: flex;
                align-items: center;
                margin: auto;
                & > a {
                    padding: 0.4em 1em;                    
                    color: $white;
                    background: $most-gray;
                    font-size: 0.85rem;
                    transition: all $hover-transition;
                    #{$hovfoc} {
                        background: $most-red;
                    } // hover-focus
                } // & > a
    
            } // .link
    
        } // .container

        &.slick-active {
            transform: scale(1);
        } // &.slick-active

        &.transition-out {
            transform: scale($scale-large);
        } // .transition-out

        &.transition-delay {
            transition-delay: $delay-speed;
        } // .transition-delay
    
    } // .slick-slide

    @include breakpoint(large up) {
        .slick-slide {
            .container {
                flex-direction: row;
                flex-wrap: wrap;
                
                img {
                    margin-right: 1.5rem;
                    width: 5%;
                } // img
    
                .content {
                    width: 80%;
                    margin-bottom: 0;
                    text-align: left;
                } // .content
        
                .link {
                    width: 10%;
                } // .link
        
            } // .container
        } // .slick-slide
    } // large up

} // .home-slider